import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { Textarea } from '../components/ui/textarea';

const API_URL = process.env.REACT_APP_API_URL;

const determineRarity = (rate) => {
  if (rate >= 0.01) return 'Common';
  else if (rate >= 0.005) return 'Uncommon';
  else if (rate >= 0.0015) return 'Rare';
  else return 'Jackpot';
};

const rewardSets = {
  ailionoaBox: {
    rates: `0.13% 	Glorious Ailionoa Classic Wings
0.13% 	Pure Ailionoa Classic Wings
0.13% 	Fallen Ailionoa Classic Wings
0.13% 	Passionate Ailionoa Classic Wings
0.13% 	Glorious Ailionoa Classic Muffler
0.13% 	Fancy Ailionoa Classic Muffler
0.13% 	Tranquil Ailionoa Classic Muffler
0.13% 	Graceful Ailionoa Classic Muffler
0.13% 	Special Ailionoa Chic Coat (M)
0.13% 	Special Ailionoa Chic Coat (F)
0.13% 	Special Ailionoa Classic Coat (M)
0.13% 	Special Ailionoa Classic Coat (F)
0.18% 	Ailionoa Chic Coat (M)
0.18% 	Ailionoa Chic Coat (F)
0.18% 	Ailionoa Classic Coat (M)
0.18% 	Ailionoa Classic Coat (F)
0.21% 	Ailionoa Chic Gloves (M)
0.21% 	Ailionoa Chic Gloves (F)
0.21% 	Ailionoa Chic Shoes (M)
0.21% 	Ailionoa Chic Shoes (F)
0.18% 	Ailionoa Chic Styled Wig (M)
0.18% 	Ailionoa Chic Styled Wig (F)
0.18% 	Blush of Spring (Face Accessory Slot Exclusive)
0.18% 	Ailionoa Chic Glasses (Face Accessory Slot Exclusive)
0.18% 	Heartful Bunny Stardust
0.18% 	Coronal Light Halo
0.18% 	Coronal Darkness Halo
0.18% 	Highborn Light Halo
0.18% 	Highborn Darkness Halo
0.14% 	Frost Umbrella Appearance Scroll
0.14% 	Frost Great Scythe Appearance Scroll
0.14% 	Frost Mandolin Appearance Scroll
0.20% 	Midnight Investigator Staff Appearance Scroll
0.20% 	Rain Casting Effect Change Card (Bodacious Party) Coupon
0.20% 	Meteor Strike Effect Change Card (Bodacious Bear) Coupon
0.20% 	Charge Effect Change Card (Flaming Blitz) Coupon
0.20% 	Act 7: Climactic Crash Effect Change Card (Blazing Cyclone) Coupon
0.20% 	Special Regal Retreat Elegant Dress (F)
0.20% 	Special Regal Retreat Elegant Attire (M)
0.20% 	Special Regal Retreat Charming Dress (F)
0.20% 	Special Regal Retreat Charming Attire (M)
0.20% 	Special Savvy Socialite Dress (F)
0.20% 	Special Savvy Socialite Outfit (M)
0.85% 	Regal Retreat Wig (F)
0.85% 	Regal Retreat Wig (M)
0.85% 	Regal Retreat Bracelet (F)
0.85% 	Regal Retreat Bracelet (M)
0.85% 	Regal Retreat Shoes (F)
0.85% 	Regal Retreat Shoes (M)
0.85% 	Savvy Socialite Dress (F)
0.85% 	Savvy Socialite Outfit (M)
0.85% 	Savvy Socialite Wig (F)
0.85% 	Savvy Socialite Wig (M)
0.85% 	Savvy Socialite Fedora
0.23% 	Black Rose Staff Appearance Scroll
0.85% 	Royal Mage Staff Appearance Scroll
0.85% 	Halloween Spider Lance Appearance Scroll
0.85% 	Midnight Investigator Sword Appearance Scroll
0.57% 	Frost Greatsword Appearance Scroll
0.85% 	Frost Bow Appearance Scroll
0.85% 	Nail Bat Appearance Scroll
0.85% 	Chainsaw Appearance Scroll
0.85% 	Musician's Hard Case Appearance Scroll
0.71% 	Musician's Hard Case
0.85% 	Musician's Broken Mic Appearance Scroll
0.85% 	Musician's Broken Mic
0.28% 	Antique Cooking Knife
0.37% 	Antique Ladle
0.37% 	Antique Cooking Pot
1.41% 	Regal Retreat Elegant Dress (F)
1.41% 	Regal Retreat Elegant Attire (M)
1.41% 	Regal Retreat Charming Dress (F)
1.41% 	Regal Retreat Charming Attire (M)
1.41% 	Savvy Socialite Gloves (F)
1.41% 	Savvy Socialite Gloves (M)
1.27% 	Savvy Socialite Boots (F)
1.27% 	Savvy Socialite Shoes (M)
1.41% 	Savvy Socialite Wig and Earrings (F)
1.41% 	Savvy Socialite Wig and Sunglasses (M)
1.41% 	Homestead Royal Mage's Tome
1.41% 	Homestead Housing Small Magic Library Bookshelf
1.41% 	Fixed Color Hair Dye Ampoule (#2881DD)
1.41% 	Fixed Color Hair Dye Ampoule (#7D0328)
1.41% 	Fixed Color Hair Dye Ampoule (#E2DFE5)
1.27% 	Monochromatic Dark Red Pack (#8B0000)
1.41% 	Monochromatic Gray Pack (#808080)
1.41% 	Monochromatic Pink Pack (#f095aa)
1.41% 	Pleasure Reading Rug
1.41% 	Bookshelf and Beanbag Chair
1.41% 	Homestead Mysterious Mansion Garden Hedge 1
1.41% 	Homestead Mysterious Mansion Garden Hedge 2
1.41% 	Homestead Mysterious Mansion Garden Hedge 3
1.41% 	Homestead Mysterious Mansion Garden Hedge 4
1.41% 	Homestead Mysterious Mansion Garden Hedge 5
1.41% 	Baltane Elite Mission Pass Selection Box
1.47% 	Speed Walk Potion 40% (30 min)
1.47% 	Light Physical Power Potion (2 Hours)
1.47% 	Light Magic Power Potion (2 hrs.)
1.47% 	Fixed Color Dye Ampoule (#ffd700)
1.47% 	Fixed Color Dye Ampoule (#00FFFF)
1.47% 	Fixed Color Dye Ampoule (#720000)
1.47% 	Fixed Color Metal Dye Ampoule (#00FF00)
1.47% 	Fixed Color Metal Dye Ampoule (#FFDCB8)
1.47% 	Fixed Color Metal Dye Ampoule (#800080)
1.47% 	Pet Fixed Color Dye Ampoule (#000000)
1.47% 	Pet Fixed Color Dye Ampoule (#0000FF)
1.47% 	Pet Fixed Color Dye Ampoule (#008000)
1.47% 	Fallen Fairy Boots (F)
1.47% 	Fallen Fairy Gauntlets (F)
1.47% 	Fallen Fairy Sneakers (M)
1.47% 	Fallen Fairy Gloves (M)
1.47% 	Control Marionette Training Potion
1.47% 	Life Drain Training Potion
1.47% 	Healing Training Potion
1.47% 	Bullet Storm Training Potion
1.47% 	Dischord Training Potion
1.47% 	Blush Gesture Card
1.47% 	Antsy Gesture Card
1.47% 	Alban Knights Training Stone [Hidden Floor: Aton Cimeni Anvil]
1.47% 	Alban Knights Training Stone [Difficulty: Hard]`,
    costs: {
      1: 1.5,
      11: 15,
      45: 57.5
    },
    batches: [1, 11, 45]
  },
  otherworldlyBox: {
    rates: `2.30% 	Guardian Cwn Annwn Whistle
2.30% 	Arbiter Cwn Annwn Whistle
3.07% 	Crystal Whale Whistle
3.07% 	Lightfin Whale Whistle
5.75% 	Lucky Festive Alpaca Whistle
5.75% 	Sunshine Rose Halflinger Whistle
3.07% 	Saber X Smart Key
3.83% 	White Knight X Smart Key
3.83% 	Practice Kart Smart Key
7.66% 	Ceraunus Whistle
7.66% 	Red Eye Ceraunus Whistle
10.34% 	Fallen Fairy Whistle
10.34% 	Winter Alto Whistle
10.34% 	Crystal Chime Deer Whistle
10.34% 	Astral Nimbus Whistle
10.34% 	Tassel Scooter Whistle`,
    costs: {
      1: 4.9,
      3: 14.7,
      10: 45
    },
    batches: [1, 3, 10]
  },
  beautyBox2024: {
    rates:`0.07% 	Slickback Hair Beauty Coupon (M)
0.07% 	Tousled Hair Beauty Coupon (M)
0.07% 	See-Through Short Hair Beauty Coupon (M)
0.07% 	Parted Long Hair Beauty Coupon (M)
0.07% 	Natural Parted Hair Beauty Coupon (M)
0.07% 	Outlander Natural Parted Hair Beauty Coupon (M)
0.07% 	Boho Waves Hair Beauty Coupon (F)
0.07% 	Feathered Pigtails Beauty Coupon (F)
0.07% 	Thick Wave Layered Hair Beauty Coupon (F)
0.07% 	Chic Ponytail Hair Beauty Coupon (F)
0.07% 	Cute Twintail Hair Beauty Coupon (F)
0.07% 	See-Through Long Hair Beauty Coupon (F)
0.07% 	Guardian of the Divine Beast Hair Beauty Coupon (M)
0.07% 	Bleugenne Cosmetics Hair Beauty Coupon (M)
0.07% 	Moonshadow Emissary Hair Beauty Coupon (M)
0.07% 	Erinn Merchants' Guild Hair Beauty Coupon (M)
0.07% 	Secret Forest Hair Beauty Coupon (M)
0.07% 	Regal Retreat Hair Beauty Coupon (M)
0.07% 	Everyday Wavy Hair Beauty Coupon (M)
0.07% 	Tidal Guardian Hair Beauty Coupon (M)
0.07% 	Sweet Dreams Hair Beauty Coupon (F)
0.07% 	Sweet Academy Hair Beauty Coupon (F)
0.07% 	Blessed Ornament Hair Beauty Coupon (F)
0.07% 	Guardian of the Divine Beast Hair Beauty Coupon (F)
0.07% 	Bleugenne Cosmetics Hair Beauty Coupon (F)
0.07% 	Moonshadow Emissary Hair Beauty Coupon (F)
0.07% 	Elemental Harmony Hair Beauty Coupon (F)
0.07% 	Tidal Guardian Hair Beauty Coupon (F)
0.10% 	Clear Glittering Eyes (Curative Blessing) Beauty Coupon
0.10% 	White-Crested Clear Glittering Eyes (Curative Blessing) Beauty Coupon
0.10% 	Lidded Eyes (Spirit's Energy) Beauty Coupon
0.10% 	White-Crested Lidded Eyes (Spirit's Energy) Beauty Coupon
0.10% 	Combative Eyes (Reaper) Beauty Coupon
0.10% 	White-Crested Combative Eyes (Reaper) Beauty Coupon
0.10% 	Narrow Gaze Eyes (Gem Powder) Beauty Coupon
0.10% 	White-Crested Narrow Gaze Eyes (Gem Powder) Beauty Coupon
0.10% 	Serious Eyes (Light Aura) Beauty Coupon
0.10% 	White-Crested Serious Eyes (Light Aura) Beauty Coupon
0.10% 	Weary Eyes (Good Fortune) Beauty Coupon
0.10% 	White-Crested Weary Eyes (Good Fortune) Beauty Coupon
0.10% 	Soulful Eyes (Aqua) Beauty Coupon
0.10% 	White-Crested Soulful Eyes (Aqua) Beauty Coupon
0.10% 	Serious Determined Eyes (Gaze of Revelation) Beauty Coupon
0.10% 	White-Crested Serious Determined Eyes (Gaze of Revelation) Beauty Coupon
0.10% 	Enchanting Mysterious Eyes (Ghostly Reflection) Beauty Coupon
0.10% 	White-Crested Enchanting Mysterious Eyes (Ghostly Reflection) Beauty Coupon
0.10% 	Childlike Soulful Eyes (Hearts) Beauty Coupon
0.10% 	White-Crested Childlike Soulful Eyes (Hearts) Beauty Coupon
0.10% 	Sparkling Eyes (Dim Starlight) Beauty Coupon
0.10% 	White-Crested Sparkling Eyes (Dim Starlight) Beauty Coupon
0.10% 	Piercing Glare Eyes (Jackpot) Beauty Coupon
0.10% 	White-Crested Piercing Glare Eyes (Jackpot) Beauty Coupon
0.10% 	Long Hair with Spiky Bangs Beauty Coupon (F)
0.10% 	Carefree Long Hair Beauty Coupon (F)
0.10% 	Outlander Parted Hair Beauty Coupon (M)
0.11% 	Tousled Long Hair Beauty Coupon (F)
0.11% 	Relaxed Twintail Long Hair Beauty Coupon (F)
0.11% 	Choppy Long Hair Beauty Coupon (M)
0.11% 	Seductive Eyes (Lovely Heart) Beauty Coupon
0.11% 	Seris Long Wavy Hair Beauty Coupon (F)
0.12% 	Academy Charmer's Hair Beauty Coupon (F)
0.12% 	Deirbhile Hair Beauty Coupon (F)
0.12% 	Parted Perm Hair Beauty Coupon (M)
0.12% 	Long Wavy Ponytail Hair Beauty Coupon (F)
0.12% 	Looping Tails Hair Beauty Coupon (F)
0.12% 	Shadow Vagabond Long Hair Beauty Coupon (M)
0.12% 	Specter's Eyes (Two-Tone) Beauty Coupon
0.12% 	Parted Long Hair Beauty Coupon (M)
0.12% 	Beauty's Hair Beauty Coupon (F)
0.12% 	Hair with Bubble Pigtails Beauty Coupon (F)
0.12% 	Winter Forest Wanderer Normal Hair Beauty Coupon (F)
0.12% 	Innocent Goddess Long Wavy Hair Beauty Coupon (F)
0.12% 	Irish Twin Tail Hair Beauty Coupon (F)
0.12% 	Secret Garden Hair Beauty Coupon (F)
0.12% 	Spectral Eyes (Two-Tone) Beauty Coupon
0.12% 	Arrogant Eyes (Two-Tone) Beauty Coupon
0.13% 	Hydrangea Hair Beauty Coupon (M)
0.13% 	Cute Devilish Mouth Beauty Coupon
0.13% 	Soldier's Short-Tressed Hair Beauty Coupon (F)
0.13% 	Fodla Hair Beauty Coupon (F)
0.13% 	Constellation Guardian Hair Beauty Coupon (F)
0.13% 	Autumnal Diligent Hair Beauty Coupon (M)
0.13% 	Royal Mage Trim Hair Beauty Coupon (F)
0.13% 	Demon Lord's Eyes (Two-Tone) Beauty Coupon
0.13% 	Eluned Soldier's Short-Tressed Hair Beauty Coupon (M)
0.13% 	Dabby Urban Hair Beauty Coupon (M)
0.13% 	Innocent Long Wave Hair Beauty Coupon (F)
0.14% 	Rabbie Elle Twin Tailed Hair Beauty Coupon (F)
0.14% 	White-Crested Spectral Eyes (Ghastly Glare) Beauty Coupon
0.23% 	Clear Glittering Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Clear Glittering Eyes (Two-Tone) Beauty Coupon
0.23% 	Lidded Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Lidded Eyes (Two-Tone) Beauty Coupon
0.23% 	Combative Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Combative Eyes (Two-Tone) Beauty Coupon
0.23% 	Narrow Gaze Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Narrow Gaze Eyes (Two-Tone) Beauty Coupon
0.23% 	Serious Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Serious Eyes (Two-Tone) Beauty Coupon
0.23% 	Weary Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Weary Eyes (Two-Tone) Beauty Coupon
0.23% 	Soulful Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Soulful Eyes (Two-Tone) Beauty Coupon
0.23% 	Serious Determined Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Serious Determined Eyes (Two-Tone) Beauty Coupon
0.23% 	Enchanting Mysterious Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Enchanting Mysterious Eyes (Two-Tone) Beauty Coupon
0.23% 	Childlike Soulful Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Childlike Soulful Eyes (Two-Tone) Beauty Coupon
0.23% 	Sparkling Eyes (Two-Tone) Beauty Coupon
0.23% 	White-Crested Sparkling Eyes (Two-Tone) Beauty Coupon
0.28% 	Piercing Glare Eyes (Two-Tone) Beauty Coupon
0.28% 	White-Crested Piercing Glare Eyes (Two-Tone) Beauty Coupon
0.28% 	Gently Smiling Lips Beauty Coupon
0.28% 	Easygoing Fang Smile Beauty Coupon
0.28% 	Mischievous Fang Smile Beauty Coupon
0.28% 	Pondering Lips Beauty Coupon
0.28% 	Faint Frown Beauty Coupon
0.28% 	Cool Blue Grin Beauty Coupon
0.47% 	Expressionless Mouth Beauty Coupon
0.66% 	Pointy Blush Face Beauty Coupon (F)
0.33% 	Twinkling Starlight Eyes (Dim Starlight) Beauty Coupon
0.33% 	Freedom Eyes (Two-Tone) Beauty Coupon
0.61% 	Parade Band Hair Beauty Coupon (F)
0.23% 	Prep Heart Hair Beauty Coupon (F)
0.66% 	Royal Mage Trim Hair Beauty Coupon (M)
0.70% 	Dream-Delver Eyes (Two-Tone) Beauty Coupon
0.70% 	White-Crested Seductive Eyes (Two-Tone) Beauty Coupon
0.28% 	Scarlet Nightstalker Hair Beauty Coupon (F)
0.52% 	Healing Eyes (Two-Tone) Beauty Coupon
0.70% 	Dream-Delver Eyes (Spirit's Energy) Beauty Coupon
0.61% 	Demure Eyes (Two-Tone) Beauty Coupon
0.70% 	Trim Sideburn Hair Beauty Coupon (F)
0.70% 	Shadowed Eyes (Two-Tone) Beauty Coupon
0.52% 	Winter Queen Argenta Hair Beauty Coupon (F)
0.23% 	Scholar Hair Beauty Coupon (F)
0.38% 	Demonic Eyes (Two-Tone) Beauty Coupon
0.38% 	White-Crested Discerning Eyes (Good Fortune) Beauty Coupon
0.47% 	Shadowed Eyes (Dim Starlight) Beauty Coupon
0.66% 	Llywelyn Eyes (Chilling Flame) Beauty Coupon
0.66% 	Devilish Mouth Beauty Coupon
0.56% 	Bleiddian Hair Beauty Coupon (M)
0.52% 	Freedom Eyes (Water Aura) Beauty Coupon
0.33% 	Imperial Commander Hair Beauty Coupon (M)
0.66% 	Magical Halloween Witch Hair Beauty Coupon (F)
0.66% 	Expert Assassin Hair Beauty Coupon (F)
1.52% 	Machiavellian Eyes (Two-Tone) Beauty Coupon
1.52% 	Sweet Mouth Beauty Coupon
1.28% 	Eluned Pinkie Hair Beauty Coupon (M)
1.28% 	Small Soft Lips Beauty Coupon
1.28% 	Fierce Eyes (Gem Powder) Beauty Coupon
1.52% 	Steam Engineer Hair Beauty Coupon (M)
1.52% 	White-Crested Dream-Delver Eyes (Spirit's Energy) Beauty Coupon
1.52% 	White-Crested Arrogant Eyes (Two-Tone) Beauty Coupon
1.52% 	Shrewd Eyes (Two-Tone) Beauty Coupon
1.52% 	Hopeful Mouth Beauty Coupon
1.28% 	Fierce Eyes (Two-Tone) Beauty Coupon
1.52% 	Searching Eyes (Two-Tone) Beauty Coupon
1.28% 	Dominator's Eyes (Two-Tone) Beauty Coupon
1.28% 	Sealed Lips Beauty Coupon
1.52% 	Natural Loose Wave Hair Beauty Coupon (M)
1.52% 	Drowsy Mouth Beauty Coupon
1.28% 	Sacred Light Hair Beauty Coupon (F)
1.28% 	Sharp Jawline Face Beauty Coupon (Giant M)
1.28% 	Orange Glossed Lips Beauty Coupon
1.28% 	Medium Sideburns Beauty Coupon (F)
1.28% 	Soft Lips Beauty Coupon
1.28% 	Serious Small Mouth Beauty Mark Face Beauty Coupon (M)
1.28% 	Innerlake Eyes (Two-Tone) Beauty Coupon
1.28% 	Moonlight Dreams Warlock Hair Beauty Coupon (M)
1.28% 	Altam Eyes (Binding Gaze) Beauty Coupon
1.75% 	Sharp Small Mouth Beauty Mark Face Beauty Coupon (F)
1.75% 	Brilluen Hair Beauty Coupon (F)
1.75% 	Abyssal Challenger Eyes (Two-Tone) Beauty Coupon
1.75% 	High Tail It Outta Hair Beauty Coupon (M)
1.75% 	Pointy Small Mouth Beauty Mark Face Beauty Coupon (M)
1.75% 	Sleeping Eyes Beauty Coupon
1.75% 	Li'l Kitty Mouth Beauty Coupon
1.75% 	Surf 'n' Turf Hair Beauty Coupon (M)
1.75% 	Wavelet Twin Tail Beauty Coupon (F)
1.75% 	Youthful Small Mouth Beauty Mark Face Beauty Coupon (F)
1.75% 	Mature Small Mouth Beauty Mark Face Beauty Coupon (F)
1.75% 	Silky Wavy Hair Beauty Coupon (M)
1.75% 	Blind Trim Hair Beauty Coupon (M)
1.75% 	Gleeful Kitten Mouth Beauty Coupon
1.75% 	Cute Blush Face Beauty Coupon (F)
1.75% 	Premiere Trim Hair Beauty Coupon (M)
1.75% 	White-Crested Dominator's Eyes (Two-Tone) Beauty Coupon
1.75% 	Savvy Socialite Hair Beauty Coupon (M)
1.75% 	Blep Mouth Beauty Coupon
1.75% 	Cessair's Heart Hair Beauty Coupon (F)`,
    costs: {
      1: 1.5,
      11: 15,
      45: 57.5
    },
    batches: [1, 11, 45]
  }
};

const GachaSimulator = () => {
  const [selectedSet, setSelectedSet] = useState('ailionoaBox');
  const [prizeInput, setPrizeInput] = useState(rewardSets.ailionoaBox.rates);
  const [costs, setCosts] = useState(rewardSets.ailionoaBox.costs);
  const [batches, setBatches] = useState(rewardSets.ailionoaBox.batches);
  const [rewards, setRewards] = useState([]);
  const [awardedItems, setAwardedItems] = useState({});
  const [totalDraws, setTotalDraws] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [filter, setFilter] = useState('');
  const [visitorCount, setVisitorCount] = useState(0);
  const [globalSpent, setGlobalSpent] = useState(0);

  const fetchStats = async () => {
    try {
      const response = await fetch(`${API_URL}/api/stats`);
      const data = await response.json();
      setVisitorCount(data.visitors);
      setGlobalSpent(data.totalSpent);
    } catch (error) {
      console.error('Failed to fetch stats:', error);
    }
  };

  useEffect(() => {
    const initVisitor = async () => {
      try {
        const response = await fetch(`${API_URL}/api/stats`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ type: 'visitor' }),
        });
        const data = await response.json();
        setVisitorCount(data.visitors);
        setGlobalSpent(data.totalSpent);
      } catch (error) {
        console.error('Failed to increment visitor:', error);
      }
    };
    initVisitor();
  }, []);

  useEffect(() => {
    parseRewards();
  }, [prizeInput]);

  useEffect(() => {
    setPrizeInput(rewardSets[selectedSet].rates);
    setCosts(rewardSets[selectedSet].costs);
    setBatches(rewardSets[selectedSet].batches);
  }, [selectedSet]);

  const parseRewards = () => {
    const parsedRewards = prizeInput.split('\n').map(line => {
      const parts = line.split(/\t|(?<=\%)\s+/);
      const rateStr = parts[0].trim();
      const item = parts[1].trim();
      const rate = parseFloat(rateStr.replace('%', '').trim()) / 100;
      return { item, rate };
    });
    setRewards(parsedRewards);
  };

  const drawItems = async (drawCount) => {
    const newAwardedItems = { ...awardedItems };
    for (let i = 0; i < drawCount; i++) {
      const random_number = Math.random();
      let cumulativeRate = 0;
      for (const reward of rewards) {
        cumulativeRate += reward.rate;
        if (random_number < cumulativeRate) {
          if (reward.item in newAwardedItems) {
            newAwardedItems[reward.item].count++;
          } else {
            newAwardedItems[reward.item] = {
              count: 1,
              rarity: determineRarity(reward.rate)
            };
          }
          break;
        }
      }
    }
    setAwardedItems(newAwardedItems);
    setTotalDraws(prevTotalDraws => prevTotalDraws + drawCount);

    const batchCost = costs[drawCount] || (1.5 * drawCount);
    setTotalCost(prevTotalCost => prevTotalCost + batchCost);
    
    try {
      const response = await fetch(`${API_URL}/api/stats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          type: 'spent',
          amount: batchCost 
        }),
      });
      const data = await response.json();
      setGlobalSpent(data.totalSpent);
    } catch (error) {
      console.error('Failed to update total spent:', error);
    }
  };

  const filteredItems = Object.entries(awardedItems)
    .filter(([item, data]) => filter === '' || data.rarity === filter)
    .sort((a, b) => a[0].localeCompare(b[0]));

  return (
    <div className="p-4 bg-background text-foreground min-h-screen">
      <Card className="mb-4">
        <CardHeader>Dani's Gacha Sim</CardHeader>
        <CardContent>
          <h2 className="mb-2">
            <a
              href="https://repo.danii.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Repo of my dumb projects
            </a>
          </h2>
          <div className="mb-4">
            <Button onClick={() => setSelectedSet('ailionoaBox')} className="mr-2">Ailionoa Box</Button>
            <Button onClick={() => setSelectedSet('otherworldlyBox')} className="mr-2">Otherworldly Guardians Pet Box</Button>
            <Button onClick={() => setSelectedSet('beautyBox2024')} className="mr-2">Erinn Beauty Box</Button>
          </div>
          <h4 className="mb-2">To change the rates/items, copy from the RNG rates table that is linked in every gacha</h4>
          <h4 className="mb-2">Copy only the %s and the names, leave the headers out.</h4>
          <Textarea
            value={prizeInput}
            onChange={(e) => setPrizeInput(e.target.value)}
            className="w-full mb-4"
          />
          <div className="mb-4">
            {batches.map(batchSize => (
              <Button key={batchSize} onClick={() => drawItems(batchSize)} className="mr-2">
                Draw {batchSize}
              </Button>
            ))}
          </div>
          <div className="mt-4 space-y-2">
            <div>Total Visitors: {visitorCount}</div>
            <div>Your Draws: {totalDraws} (${totalCost.toFixed(2)})</div>
            <div>Global Amount Spent: ${globalSpent.toFixed(2)}</div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>Results</CardHeader>
        <CardContent>
          <div className="mb-4">
            <Button onClick={() => setFilter('')} className="mr-2">All</Button>
            <Button onClick={() => setFilter('Common')} className="mr-2">Common</Button>
            <Button onClick={() => setFilter('Uncommon')} className="mr-2">Uncommon</Button>
            <Button onClick={() => setFilter('Rare')} className="mr-2">Rare</Button>
            <Button onClick={() => setFilter('Jackpot')}>Jackpot</Button>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Prize</TableHead>
                <TableHead>Total</TableHead>
                <TableHead>Rarity</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredItems.map(([item, data]) => (
                <TableRow key={item}>
                  <TableCell>{item}</TableCell>
                  <TableCell>{data.count}</TableCell>
                  <TableCell>{data.rarity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default GachaSimulator;
