import React from 'react';
import GachaSimulator from './components/GachaSimulator';

function App() {
  return (
    <div className="App">
      <GachaSimulator />
    </div>
  );
}

export default App;
